<template>
  <BaseModalIntro
    v-if="showValue"
    :introTitle="'Trustees'"
    :show="show"
    :showValue="showValue"
    @close="$emit('close')"
    @doNotShowAgain="doNotShowAgain"
  >
    <template v-slot:contents>
      <h5>Overview</h5>
      <p>
        Once Executors have completed the general administration of an estate
        they can then distribute assets to the beneficiaries. Where assets are
        being passed to an adult this is a simple task, however where assets are
        instead left to a minor a child or grandchild for example, somebody will
        need to look after the assets until the beneficiary comes of age; this
        the role of 'Trustees'.
      </p>

      <h5>Responsibilities</h5>

      <p>Trustees have a number of duties and responsibilities including:</p>
      <ul>
        <li>
          <p>To safeguard the Trust property;</p>
        </li>
        <li>
          <p>To invest the Trust property;</p>
        </li>
        <li>
          <p>To act with honesty and integrity;</p>
        </li>
        <li>
          <p>To consult beneficiaries;</p>
        </li>
        <li>
          <p>To keep proper records and accounts.</p>
        </li>
      </ul>
      <h5>Your Choices</h5>
      <p>
        You just need to decide who you want to appoint (often people appoint
        the same people as executors and trustees although the two roles are
        quite different). You should appoint at least two trustees, you can also
        name 'backup' trustees just in-case something happens to those first
        named.
      </p>
    </template>
  </BaseModalIntro>
</template>

<script>
import BaseModalIntro from '@/common/ui/BaseModalIntro'

export default {
  name: 'TrusteesIntroModal',
  props: ['show'],
  components: {
    BaseModalIntro
  },
  computed: {
    showValue() {
      return this.$store.getters.showIntroModals.trustees
    }
  },
  methods: {
    doNotShowAgain() {
      this.$store.commit('showIntroModals', { trustees: false })
    }
  }
}
</script>

<style></style>
