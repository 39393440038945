<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionLarge
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-trustee-seperate.png'"
        :letter="'a'"
        :selected="executorsAsTrustees === false"
        :option="'No'"
        @selected="save(false)"
      >
        <template v-slot:text>
          Choose this option if you prefer to name separate 'Trustees', those
          named will be given the specific responsibility of looking after money
          and other assets until any minor beneficiaries come of age.
        </template>
      </InputOptionLarge>
      <InputOptionLarge
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-trustee-same.png'"
        :letter="'b'"
        :selected="executorsAsTrustees"
        :option="'Yes'"
        @selected="save(true)"
      >
        <template v-slot:text>
          Choose this option if you do not wish to appoint a specific group of
          Trustees (if necessary your Executors would also be your Trustees).
        </template>
      </InputOptionLarge>
      <IntroModal
        v-show="show.intro"
        :show="show.intro"
        @close="show.intro = false"
      ></IntroModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionLarge from '@/common/ui/InputOptionLarge'
import IntroModal from '../IntroModal'

export default {
  name: 'ExecutorsAsTrustees',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionLarge,
    IntroModal
  },
  computed: {
    partner() {
      return this.$store.getters.partner
    },
    people() {
      return this.$store.getters.people
    },
    executorsAsTrustees() {
      return this.$store.getters.details.same_as_executors
    },
    isValidated() {
      if (this.executorsAsTrustees !== null) return true
      return false
    },
    forwardTo() {
      if (this.executorsAsTrustees) return '/trustees/check_and_confirm'
      else if (this.executorsAsTrustees === false && this.partner)
        return '/trustees/partner'
      else if (this.executorsAsTrustees === false && !this.partner)
        return '/trustees/primary'
      return '/trustees/primary'
    }
  },
  data() {
    return {
      show: {
        intro: true
      },
      loading: false,
      number: '1',
      heading: 'Will your Executors also be your Trustees?',
      subHeading:
        '   Executors deal with the administration of an estate and Trustees look after ' +
        'assets until they can be distributed. Your Executors could also be your ' +
        'Trustees, however if you are leaving assets to young children the Trustees role ' +
        'could take several years to complete, therefore it may be preferable to give a ' +
        'separate group of people the specific responsibility of looking after and ' +
        'investing assets until the minor beneficiaries come of age.'
    }
  },
  methods: {
    save(value) {
      this.$store.commit('details', {
        same_as_executors: value
      })
      if (value === true) {
        this.resetTrustees()
      }
    },
    resetTrustees() {
      this.people.forEach((person) => {
        let personCopy = Object.assign({}, person)
        personCopy.trustee_primary = false
        personCopy.trustee_secondary = false
        personCopy.trustee_backup = false
        this.$store.commit('personAdd', personCopy)
      })
      this.$store.commit('details', {
        trustees_backup_required: false
      })
    }
  }
}
</script>

<style></style>
